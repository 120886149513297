import axios from "axios";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

import sha256 from "js-sha256";
export default function graphqlAxios({ url, headers={}, query, mutation, variables={} }) {
  if (process.env.NODE_ENV === "development") {
    if (headers["Authorization"] === undefined) { 
      headers["Authorization"] = `Bearer ${getCookie("token")}`;
    }
  }
  const fullUrl = `${process.env.REACT_APP_API_GO_URL}/${url}`;
  const normalConfig = {
    url: fullUrl,
    method: "post",
    headers,
    data: {
      query,
      variables
    },
    withCredentials: true
  };
  if (mutation) {
    normalConfig.data.query = mutation;
    return new Promise((resolve, reject) => {
      axiosNormalGraphql(resolve, reject, normalConfig);
    });
  }
  // persisted query
  const persistedParam = {
    hash: sha256(query),
  }
  if( (typeof variables === "object") && !!Object.keys(variables).length) {
    persistedParam.variables = JSON.stringify(variables);
  }
  const persistedConfig = {
    url: fullUrl,
    method: "get",
    headers,
    params: persistedParam,
    withCredentials: true
  };
  return new Promise((resolve, reject) => {
    axios(persistedConfig)
      .then(response => {
        if (response.data && response.data.errors && response.data.errors.length !== 0) {
          axiosNormalGraphql(resolve, reject, normalConfig);
        } else {
          resolve(response);
        }
      })
      .catch(e => {
        if (e.response && e.response.status === 400) {
          axiosNormalGraphql(resolve, reject, normalConfig);
        } else {
          reject(e);
        }
      });
  });
}

function axiosNormalGraphql(resolve, reject, normalConfig) {
  axios(normalConfig)
    .then(response => {
      resolve(response);
    })
    .catch(e_ => {
      reject(e_);
    });
}
