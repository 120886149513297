import graphqlAxios from "./_graphqlAxios";
import { Mixpanel } from "./mixpanel";

export const initiateLogin = async () => {
  window.location.href = `${process.env.REACT_APP_API_GO_URL}/login/google`;
}

export async function getUserInfo() {
  try {
    const res = await graphqlAxios({
      url: `gateway/graphql`,
      query: `
        query currentUser {
          currentUser {
            email
            profile {
              fullName
              photo
            }
          }
        }
        `,
      variables: {}
    }).then(({ data = {} }) => {
      if (data.errors) {
        return {
          success: false,
          message: data.errors[0].message
        };
      }
      const currentUser = data?.data?.currentUser || {};
      const profile = currentUser?.profile || {};
      Mixpanel.identify(currentUser?.email)
      Mixpanel.people.set({
        $name: profile.fullName,
        $email: currentUser.email
      })
      return {
        user: {
          id: "",
          email: currentUser.email,
          name: profile.fullName,
          profile_pic: profile.photo,
          created_time: "",
          edited_time: "",
        }
      };
    });
    return res;
  } catch (e) {
    console.error(e);
    return {
      success: false,
      code: e.code,
      message: e.message
    };
  }
}
export async function RequestLogout() {
  try {
    const res = await graphqlAxios({
      url: `gateway/graphql`,
      mutation: `
        mutation userLogout {
          logout
        }
        `,
      variables: {}
    }).then(({ data = {} }) => {
      if (data.errors) {
        return {
          success: false,
          message: data.errors[0].message
        };
      }
      return data.data;
    });
    Mixpanel.reset()
    return res;
  } catch (e) {
    console.error(e);
    return {
      success: false,
      code: e.code,
      message: e.message
    };
  }
}

