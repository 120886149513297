import mixpanel from "mixpanel-browser";

const env_check = true;

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: !env_check,
  track_pageview: true,
  persistence: 'localStorage',
  ignore_dnt: true
});

const actions = {
  identify: (user_id) => {
    if (env_check) mixpanel.identify(user_id);
  },
  reset: () => {
    if (env_check) mixpanel.reset()
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props)
    }
  }
}

const eventList = {
  CLICK_CREATE_PROJECT_BTN : "click Create Project button",
  SUCCESS_CREATE_PROJECT : "Success Create Project",
  EDIT_PROJECT : "Edit existing Project",
  CLICK_DELETE_PROJECT_BTN: "click Delete Project button",
  SUCCESS_DELETE_PROJECT : "Success Delete Project",

  CLICK_ADD_CANDIDATE_BTN : "click Add Candidate button",
  SUCCESS_CREATE_CANDIDATE : "Success Create Candidate",
  CLICK_DELETE_CANDIDATE_BTN: "click Delete Candidate button",
  SUCCESS_DELETE_CANDIDATE : "Success Delete Candidate",

  CLICK_ADD_INTERVIEW_BTN : "click Add Interview button",
  SUCCESS_CREATE_INTERVIEW : "Success Create Interview",
  CLICK_DELETE_INTERVIEW_BTN: "click Delete Interview button",
  SUCCESS_DELETE_INTERVIEW : "Success Delete Interview",

}

export const Mixpanel = actions
export const MixpanelEvent = eventList
